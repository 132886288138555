import { useEffect } from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Permission, RoutesItem } from "Routes";
import ScrollTop from "components/common/ScrollTop";

import AuthRoute from "components/Guard/AuthRoute";

function App() {
  useEffect(() => {
    RoutesItem.forEach((v: any) => v.component.preload());
  }, []);

  return (
    <Router>
      <ScrollTop />
      <Routes>
        {RoutesItem.map((v: any) => (
          // <Route
          //   key={v.path}
          //   path={v.path}
          //   element={
          //     <MainContainer>
          //       <v.component />
          //     </MainContainer>
          //   }
          // />
          <Route
            key={v.path}
            path={v.path}
            element={
              v.permission === Permission.PRIVATE ? (
                <AuthRoute>
                  <v.component />
                </AuthRoute>
              ) : (
                <v.component />
              )
            }
          />
        ))}
      </Routes>
    </Router>
  );
}

export default App;
