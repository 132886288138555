import styled from "styled-components";
import mediaSize from "styles/mediaSize";

export const ButtonHoverColor = "#682e71";
export const MainColor = "#4d2054";
export const ShadowColor = "#01B0C8";
export const StartButtonColor = "#00FFFF";
export const BackgroundColor = "#141820";
export const GenomyColor = "#272A30";
export const GenomyBackgroundColor = "#141820";
export const GenomyTabColor = "#393939";
export const GenomyTabFontColor = "#c1c1c1";

export const LayoutContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${BackgroundColor};
`;

export const LayoutWrap = styled.div`
  width: 440px;
  padding: 20px;
  background-color: ${BackgroundColor};
  overflow: hidden;
  @media only screen and (max-width: ${mediaSize.W767}) {
    width: 100%;
  }
`;

export const ItemContainer = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
`;

export const ItemWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ItemLine = styled.div`
  width: 50px;
  height: 3px;
  margin: 5px 0 25px 0;
  background-color: ${StartButtonColor};
`;

export const ItemTitle = styled.dt`
  display: flex;
  line-height: 130%;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
`;

export const ItemDesc = styled.dd`
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  text-align: left;
`;

export const BgSection = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
export const BgBackground = styled.section`
  position: absolute;
  width: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Ul = styled.ul`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: ${mediaSize.W767}) {
    display: grid;
    padding: 10px;
  }
`;

export const Li = styled.li`
  display: flex;
  flex: 1;
  margin: 40px;
`;

export const H1 = styled.h1`
  font-size: 4.2rem;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;

  @media only screen and (max-width: ${mediaSize.W767}) {
    font-size: 2.5rem;
    margin-top: 40px;
  }
`;

export const H2 = styled.h2`
  font-size: 36px;
  margin-bottom: 30px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  line-height: 130%;
  white-space: pre-line;
  @media only screen and (max-width: ${mediaSize.W767}) {
    font-size: 28px;
  }
`;

export const H3 = styled.h3`
  font-size: 25px;
  margin-bottom: 30px;
`;

export const Input = styled.input`
  font-size: 15px;
  padding: 10px;
  border: 1px solid rgba(175, 175, 175, 0.4);
  border-radius: 4px;
  background-color: ${BackgroundColor};
  color: #ffffff;
  &:focus {
    padding: 10px;
    transition: 0.5s ease;
    background-color: #11131a;
    border: 1px solid ${StartButtonColor};
  }
`;

export const Message = styled.p`
  font-size: 14px;
  color: #00c0cc;
  margin-top: 5px;
  margin-bottom: 30px;
  /* white-space: pre;
  white-space: pre-line;
  white-space: pre-wrap; */
`;

export const TextButtonWrap = styled.div`
  margin-top: 35px;
  width: 100%;
  text-align: center;
`;

export const TextButton = styled.button`
  font-size: 16px;
  text-decoration: underline;
  text-underline-position: under;
  color: #ffffff;
  background-color: ${BackgroundColor};
  padding-bottom: 21px;
  &:hover {
    color: ${StartButtonColor};
    transition: 0.5s ease;
  }
`;

export const FormContainer = styled.form`
  width: 420px;
  height: 100%;
  .input_select_option_label {
    font-size: 15px;
    margin-bottom: 10px;
    color: #ffffff;
  }
  input[type="checkbox"] {
    accent-color: ${StartButtonColor};
    margin-right: 15px;
  }
  a {
    color: #ffffff;
    cursor: pointer;
    text-decoration: underline;
  }
  a:hover {
    color: ${StartButtonColor};
  }
  @media only screen and (max-width: ${mediaSize.W767}) {
    width: 100%;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;
export const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  margin-right: 15px;
`;
export const CheckboxLabel = styled.div`
  p {
    width: 100%;
    color: #9e9e9e;
  }
`;

export const GenebleH1 = styled.h1`
  color: #ff9900;
  font-size: 1.15rem;
  font-family: Inter !important;
  margin-bottom: 5px;
  font-weight: 350;
`;

// export const Img = styled.img`
//   width: 45px;
//   height: 45px;
//   margin-right: 15px;
//   @media only screen and (max-width: ${mediaSize.W1025}) {
//     width: 35px;
//     height: 35px;
//     margin-right: 10px;
//   }
// `;

// export const Span = styled.span`
//   display: inline-block;
//   font-size: 1.875rem;
//   margin-bottom: 15px;
//   @media only screen and (max-width: ${mediaSize.W1025}) {
//     font-size: 1.563rem;
//     margin-bottom: 10px;
//   }
// `;

// export const ImgIcon = styled.img`
//   width: 20px;
//   height: 20px;
// `;
