import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import {
  AUTH_CODES,
  accesTokenReissue,
  getAccessToken,
  getProfileToken,
  logout,
} from "./auth";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
  },
});

const onRequest = (
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig => {
  const { method, url } = config;
  console.log(`🛫 [API - REQUEST] ${method?.toUpperCase()} ${url}`);
  return config;
};

const onResponse = (res: AxiosResponse): AxiosResponse => {
  const { method, url } = res.config;

  const { status, statusText } = res;
  if (status === 200) {
    console.log(
      `🛬 [API - RESPONSE] ${method?.toUpperCase()} ${url} | ${status} : ${statusText} `,
    );
  } else {
    console.log(
      `🚨 [API - ERROR RESPONSE] ${method?.toUpperCase()} ${url} | ${status}`,
    );
  }
  return res;
};

const onError = async (error: AxiosError | Error): Promise<AxiosError> => {
  if (axios.isAxiosError(error)) {
    const { method, url } = error.config as InternalAxiosRequestConfig;
    const { config } = error;
    if (error.response) {
      const { status, statusText } = error.response;

      console.log(
        `🚨 [API - ERROR ERROR] ${method?.toUpperCase()} ${url} | ${status} : ${statusText}`,
      );

      if (status === 401) {
        switch (error.response.data.code) {
          case AUTH_CODES.ERROR_INVALID_TOKEN:
            if (url === "/accounts/token/refresh/") {
              logout();
              break;
            }
            try {
              accesTokenReissue();
            } catch (refreshErr) {
              logout();
              return Promise.reject(error);
            }
        }
      }
    }
  } else {
    console.log(`🚨 [API] | Error ${error.message}`);
  }
  return Promise.reject(error);
};

axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  if (getAccessToken())
    config.headers["Authorization"] = "JWT " + getAccessToken();
  if (getProfileToken()) config.headers["Profile"] = getProfileToken()!;
  return config;
});

axiosInstance.interceptors.request.use(onRequest);
axiosInstance.interceptors.response.use(onResponse, onError);

export default axiosInstance;
