import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GlobalStyle from "styles/GlobalStyle";
import Loading from "components/common/Loading";
import { QueryClient, QueryClientProvider } from "react-query";
import ErrorBoundary from "components/common/ErrorBoundary";
import Spinner from "components/common/Spinner";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      suspense: true,
      useErrorBoundary: true,

      select: ({ data }: any) => data?.result || data,
    },
    mutations: {
      useErrorBoundary: true,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <Suspense fallback={<Spinner />}>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <GlobalStyle />
        <App />
      </QueryClientProvider>
    </ErrorBoundary>
  </Suspense>,
);

reportWebVitals();
