import { localstorageKey } from "apis/auth";
import { Navigate } from "react-router-dom";
export default ({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement => {
  if (!localStorage.getItem(localstorageKey.genple_token)) {
    return <Navigate replace to="/login" />;
  }
  return children;
};
