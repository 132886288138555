interface MediaSize {
  W1925: string;
  W1445: string;
  W1444: string;
  W1440: string;
  W1400: string;
  W1023: string;
  W768: string;
  W767: string;
  W400: string;
  W230: string;
}
const sizes: MediaSize = {
  W1925: "1925px",
  W1445: "1447px",
  W1444: "1444px",
  W1440: "1440px",
  W1400: "1400px",
  W1023: "1023px",
  W768: "768px",
  W767: "767px",
  W400: "400px",
  W230: "230px",
};

export default sizes;
