import styled from "styled-components";
import { BackgroundColor, StartButtonColor } from "styles/baseStyles";

const Spinner = () => {
  return (
    <Container className="loadingBox">
      <div className="spinnerBox"></div>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${BackgroundColor};

  .spinnerBox {
    width: 35px;
    height: 35px;
    border: 3px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    border-top-color: ${StartButtonColor};
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;

export default Spinner;
