import { createBrowserHistory } from "history";
import { refreshToken } from "./services/accountService";

export const AUTH_CODES = {
  SUCCESS: "SUCCESS",
  ERROR_UNAUTHORIZED: "Unauthorized",
  ERROR_INVALID_TOKEN: "token_not_valid",
  ERROR_EXPIRED_REFRESH_TOKEN: "ERROR_EXPIRED_REFRESH_TOKEN",
  ERROR_INVALID_REFRESH_TOKEN: "ERROR_INVALID_REFRESH_TOKEN",
};

export const localstorageKey = {
  genple_token: "genple_token",
  genple_refresh_token: "genple_refresh_token",
  genple_profile_token: "genple_profile_token",
};

//토큰저장
export const setAuthTokenToLocalStorage = (
  accessToken: string,
  refreshToken: string,
) => {
  localStorage.setItem(localstorageKey.genple_token, accessToken);
  localStorage.setItem(localstorageKey.genple_refresh_token, refreshToken);
};

export const setProfileTokenToLocalStorage = (profileToken: string) => {
  localStorage.setItem(localstorageKey.genple_profile_token, profileToken);
};

//토큰가져오기
export const getAccessToken = () => {
  return localStorage.getItem(localstorageKey.genple_token);
};

export const getRefreshToken = () => {
  return localStorage.getItem(localstorageKey.genple_refresh_token);
};

export const getProfileToken = () => {
  return localStorage.getItem(localstorageKey.genple_profile_token);
};

export const accesTokenReissue = async () => {
  try {
    const accessToken = await refreshToken(getRefreshToken());
    setAuthTokenToLocalStorage(accessToken!, getRefreshToken()!);
    return accessToken;
  } catch (error) {
    logout();
  }
};

export const logout = () => {
  localStorage.clear();
  createBrowserHistory().push("/login");
  window.location.reload();
};
