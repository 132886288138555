import * as response from "interface/response/accountResponse";
import axiosInstance from "../axiosInstance";
import {
  IModifyAccountInfoParams,
  IResetPasswordParams,
} from "interface/request/accountRequest";
import { AxiosResponse } from "axios";

export const createProfileToken = (name: string) =>
  axiosInstance.post("/project/profile/", { name });

export const getCredit = () => axiosInstance.post(`/accounts/credit/`, {});

export const porfileId = (): Promise<
  response.ProfileIdResponse[] | undefined
> => axiosInstance.get("/project/profile/");

export const porfileToken = (
  profileId: string | undefined,
): Promise<response.PropfileTokenResponse> =>
  axiosInstance.get(`/project/profile/${profileId}/token/`);

export const refreshToken = async (refreshToken: string | null | undefined) => {
  try {
    const { data } = await axiosInstance.post<response.RefreshTokenResponse>(
      `/accounts/token/refresh/`,
      {
        refresh: refreshToken,
      },
    );
    return data.access;
  } catch (error: unknown) {
    // const e = error as AxiosError;
    // logout();
  }
};

export const accountsUser = async (): Promise<response.AccountsUserResponse> =>
  axiosInstance.get("/accounts/user/");

export const paymentSubscription = async (): Promise<
  response.PaymentSubscriptionResponse[]
> => axiosInstance.get("/payment/subscription/");

export const individualMember =
  async (): Promise<response.IndividualMemberResponse> =>
    axiosInstance.get("/accounts/user/individual-member/");

export const modifyAccountInfo = async (
  params: IModifyAccountInfoParams,
): Promise<AxiosResponse> =>
  axiosInstance.post(`/accounts/user/modify/`, params);

export const resetPasswordSendEmail = async (email: string) =>
  axiosInstance.post(`/accounts/password/reset/`, { email });

export const confirmMyId = async (email: string) =>
  axiosInstance.post(`/accounts/get_id/`, { email });
