import lazy from "utils/lazy";

export enum Permission {
  PUBLIC,
  PRIVATE,
}

interface RoutesProps {
  label: string;
  path: string;
  component: any;
  permission: Permission;
}

export const RoutesItem: RoutesProps[] = [
  {
    label: "Main",
    path: "/",
    component: lazy(() => import("pages/Main/Main")),
    permission: Permission.PUBLIC,
  },
  {
    label: "Login",
    path: "/login",
    component: lazy(() => import("pages/Login/Login")),
    permission: Permission.PUBLIC,
  },
  {
    label: "ContactUs",
    path: "/contact-us",
    component: lazy(() => import("pages/ContactUs/ContactUs")),
    permission: Permission.PUBLIC,
  },
  {
    label: "Register",
    path: "/register/step1",
    component: lazy(() => import("pages/Register/Step1")),
    permission: Permission.PUBLIC,
  },
  {
    label: "Register",
    path: "/register/indivisual/step2",
    component: lazy(() => import("pages/Register/Indivisual/Step2")),
    permission: Permission.PUBLIC,
  },
  {
    label: "Register",
    path: "/register/indivisual/step3",
    component: lazy(() => import("pages/Register/Indivisual/Step3")),
    permission: Permission.PUBLIC,
  },
  {
    label: "Register",
    path: "/register/indivisual/step4",
    component: lazy(() => import("pages/Register/Step4")),
    permission: Permission.PUBLIC,
  },
  {
    label: "Register",
    path: "/register/company/step2",
    component: lazy(() => import("pages/Register/Company/Step2")),
    permission: Permission.PUBLIC,
  },
  {
    label: "Register",
    path: "/register/privacyPolicy",
    component: lazy(() => import("pages/Register/PrivacyPolicy")),
    permission: Permission.PUBLIC,
  },
  {
    label: "Register",
    path: "/register/termsOfService",
    component: lazy(() => import("pages/Register/TermsOfService")),
    permission: Permission.PUBLIC,
  },
  {
    label: "Lost Password",
    path: "/lost-password",
    component: lazy(() => import("pages/LostPassword/LostPassword")),
    permission: Permission.PUBLIC,
  },
  {
    label: "Confirm Id",
    path: "/confirm-id",
    component: lazy(() => import("pages/ConfirmMyId/ConfirmMyId")),
    permission: Permission.PUBLIC,
  },
  {
    label: "Complete",
    path: "/complete",
    component: lazy(() => import("pages/LostPassword/Complete")),
    permission: Permission.PUBLIC,
  },

  {
    label: "genomy",
    path: "/genomy",
    component: lazy(() => import("pages/Genomy/Genomy")),
    permission: Permission.PRIVATE,
  },
  {
    label: "genomy",
    path: "/genomy/basic",
    component: lazy(() => import("pages/Genomy/Basic")),
    permission: Permission.PRIVATE,
  },
  {
    label: "genomy",
    path: "/genomy/ai",
    component: lazy(() => import("pages/Genomy/Ai")),
    permission: Permission.PRIVATE,
  },
  {
    label: "genomy",
    path: "/genomy/analysis",
    component: lazy(() => import("pages/Genomy/Analysis")),
    permission: Permission.PRIVATE,
  },
  {
    label: "genomy",
    path: "/genomy/analysis/ai",
    component: lazy(() => import("pages/Genomy/AiAnalysis")),
    permission: Permission.PRIVATE,
  },
  {
    label: "genomy",
    path: "/genomy/analysis/basic",
    component: lazy(() => import("pages/Genomy/BasicAnalysis")),
    permission: Permission.PRIVATE,
  },
  {
    label: "genomy",
    path: "/genomy/sentence",
    component: lazy(() => import("pages/Genomy/Sentence")),
    permission: Permission.PRIVATE,
  },
  {
    label: "genomy",
    path: "/genomy/myfavorites",
    component: lazy(() => import("pages/Genomy/MyFavorites")),
    permission: Permission.PRIVATE,
  },
  {
    label: "genomy",
    path: "/genomy/myaccount",
    component: lazy(() => import("pages/Genomy/MyAccount")),
    permission: Permission.PRIVATE,
  },
  {
    label: "genomy",
    path: "/genomy/store",
    component: lazy(() => import("pages/Genomy/Store")),
    permission: Permission.PRIVATE,
  },
  {
    label: "genomy",
    path: "/genomy/contactus",
    component: lazy(() => import("pages/Genomy/ContactUs")),
    permission: Permission.PRIVATE,
  },
  {
    label: "geneble",
    path: "/geneble/screening",
    component: lazy(() => import("pages/Geneble/Screening/Screening")),
    permission: Permission.PRIVATE,
  },
  {
    label: "geneble",
    path: "/geneble/result",
    component: lazy(() => import("pages/Geneble/Result/Result")),
    permission: Permission.PRIVATE,
  },
  {
    label: "geneble",
    path: "/geneble/contact-us",
    component: lazy(() => import("pages/Geneble/ContactUs/ContactUs")),
    permission: Permission.PRIVATE,
  },
  {
    label: "product",
    path: "/product",
    component: lazy(() => import("components/Product/ProductPage")),
    permission: Permission.PUBLIC,
  },
  {
    label: "reset",
    path: "/password-reset",
    component: lazy(() => import("pages/ResetPassword/ResetPassword")),
    permission: Permission.PUBLIC,
  },
  {
    label: "confirm",
    path: "/account-confirm",
    component: lazy(() => import("pages/AccountConfirm/AccountConfirm")),
    permission: Permission.PUBLIC,
  },
  {
    label: "company",
    path: "/company",
    component: lazy(() => import("pages/Company/Company")),
    permission: Permission.PUBLIC,
  },
  {
    label: "pricing",
    path: "/pricing",
    component: lazy(() => import("pages/Pricing/Pricing")),
    permission: Permission.PUBLIC,
  },
  {
    label: "board",
    path: "/board",
    component: lazy(() => import("pages/Board/Board")),
    permission: Permission.PUBLIC,
  },
];
